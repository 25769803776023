/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import AnimateHeight from "react-animate-height"

import "normalize.css"
import "../css/layout.css"
import "../css/font-awesome.min.css"
import "../../node_modules/priority-nav/dist/priority-nav-core.css"
import huizarLogo from "../images/logo@2x.png"
import mapIcon from "../images/map-icon.png"

const Layout = ({ children }) => {
  const [hovering, setHovering] = useState()

  const [hiddenMenuIsOpen, setHiddenMenuisOpen] = useState(0)

  useEffect(() => {
    console.log("hovering: " + hovering)
  })

  useEffect(() => {
    //Attach script on "document ready", make sure it runs only once "on mount"
    const script = document.createElement("script")
    script.text = `priorityNav.init({
                mainNavWrapper: "nav",
                mainNav: "ul",
                navDropdownClassName: "mm-dropdown",
                navDropdownToggleClassName: "mm-toggle",
                navDropdownLabel: "<i class='fa fa-caret-down'></i> más",
                navDropdownBreakpointLabel: "&nbsp;<i class='fa fa-bars'></i>&nbsp;",
                breakPoint: 350,
            });`
    document.body.appendChild(script)
  }, [])

  const handleClick = e => {
    setHiddenMenuisOpen(!hiddenMenuIsOpen)
  }

  const handleMouseEnter = e => {
    setHovering(e.target.href)
  }

  const handleMouseLeave = e => {
    setHovering(null)
  }

  const isActive = (isCurrent, className) => {
    if (hovering) {
      return null
    }
    return isCurrent ? { className: className + " active" } : null
  }

  const MyLink = props => (
    <Link
      {...props}
      getProps={({ isCurrent }) => isActive(isCurrent, props.className)}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  )

  return (
    <>
      <div className="top-bar">
        <div className="wrapper tb-wrapper">
          <ul className="tb-list">
            <li>
              <i className="fa fa-heart-o" />
              <span> Prestigio sin mancha</span>
            </li>
            <li className="hidden">
              <Link to="/consulta-tu-orden.html">
                <i className="fa fa-shopping-cart" /> Consulta tu orden
              </Link>
            </li>
          </ul>
          <ul className="tb-list right">
            <li className="management ">
              <span className="location">Servicio a domicilio </span>
              <span className="tel">(686) 554 28 63</span>
            </li>
            <li className="customer-service">
              <button
                className="location show-hide-btn"
                aria-label="expandir menu movil"
                onClick={handleClick}
              >
                <i className="fa fa-caret-down" />
                Atencion a clientes
              </button>
              <a href="tel:+526865528025" className="tel">
                (686) 552 80 25
              </a>
            </li>
            <li className="sh-btn-wrapper">
              <button
                className="show-hide-btn"
                aria-label="expandir menu movil"
                onClick={handleClick}
              >
                <i className="fa fa-caret-down" />
              </button>
            </li>
            <li>
              <i className="fa fa-phone" />
            </li>
          </ul>
        </div>
      </div>
      <AnimateHeight
        duration={500}
        height={hiddenMenuIsOpen ? "auto" : 0} // see props documentation bellow
      >
        <div id="hidden-menu" className="hidden-menu hidden-state">
          <div className="wrapper tb-wrapper">
            <ul className="tb-list hidden-state">
              <li className="customer-service">
                <span className="location">Atencion a clientes </span>
                <a className="tel" href="tel:+526865528025">
                  (686) 552 80 25
                </a>
                <i className="fa fa-phone" />
              </li>
              <li className="management">
                <span className="location">Servicio a domicilio </span>
                <a className="tel" href="tel:+526865542863">
                  (686) 554 28 63
                </a>
                <i className="fa fa-phone" />
              </li>
            </ul>
          </div>
        </div>
      </AnimateHeight>
      <div className="main">
        <div className="main-header">
          <div className="wrapper mh-wrapper clearfix">
            <Link className="main-logo" to="/">
              <img src={huizarLogo} width="125" height="123" alt="logotipo" />
            </Link>
            <nav className="nav">
              <ul className="main-menu" id="main-menu">
                <li>
                  <MyLink to="/">inicio</MyLink>
                </li>
                <li>
                  <MyLink to="/mision/">mision</MyLink>
                </li>
                <li>
                  <MyLink to="/servicios/">servicios</MyLink>
                </li>
                <li>
                  <MyLink to="/sucursales/" className="braches">
                    <span className="sub">horarios</span>sucursales
                  </MyLink>
                </li>
                <li>
                  <MyLink to="/contacto/">contacto</MyLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className="main-content">
          <div className="wrapper wrapper-extra">{children}</div>
        </div>
      </div>
      <div className="faq">
        <div className="wrapper wrapper-extra">
          <div className="faq-wrapper-2">
            <div className="faq-title">Preguntas frecuentes</div>
            <div className="faq-wrapper-3">
              <div className="faq-col-group clearfix">
                <div className="column">
                  <ol className="faq-list first">
                    <li>
                      <h3 className="title">
                        ¿Que tipo de prendas puedo llevar?
                      </h3>
                      <span className="content">
                        Practicamente de cualquier tipo. Una vez que te la
                        recibimos, la clasificaremos y daremos tratamiento de
                        acuerdo a su tipo y color de prenda.
                      </span>
                    </li>
                    <li>
                      <h3 className="title">
                        ¿En cuanto tiempo paso a recogerlas?
                      </h3>
                      <span className="content">
                        Podras recogerlas el mismo dia si la llevas antes de las
                        10:00am. Si es mas tarde podras pasar a recogerlas el
                        siguiente dia habil a partir de las 5:30pm.
                      </span>
                    </li>
                    <li>
                      <h3 className="title">¿Y si mis prendas son antiguas?</h3>
                      <span className="content">
                        Tambien puedes traerlas, en este caso se valoraran y de
                        existir algun riesgo se te informara sobre otras
                        opciones para su servicio y evitar algun daño en ellas.
                      </span>
                    </li>
                  </ol>
                </div>
                <div className="column">
                  <ol className="faq-list second">
                    <li>
                      <h3 className="title">¿O si traen algun defecto?</h3>
                      <span className="content">
                        Si traes prendas con algun tipo de daño (roturas,
                        quemaduras, etc) informaselo al empleado de mostrador
                        para que tu prenda no se quede sin servicio.
                      </span>
                    </li>
                    <li>
                      <h3 className="title">¿Ofrecen servicio express?</h3>
                      <span className="content">
                        Asi es, si tienes alguna necesidad en particular,
                        consulta en sucursal para ver que opciones podemos
                        ofrecerte.
                      </span>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="wrapper">
          <div className="footer-col-group clearfix">
            <div className="column first">
              <div className="footer-copyright">
                <span className="super">
                  Limpiaduria huizar de Mexicali S.A.
                </span>
                <span className="sub">
                  Prestigio sin mancha -{" "}
                  <span>
                    todos los Derechos reservados <br />
                    ©2021 - <Link to='aviso'>Aviso de privacidad</Link>
                  </span>
                </span>
              </div>
            </div>
            <div className="column second">
              <div className="footer-address">
                <img className="icon" src={mapIcon} alt="" />
                Av. Zaragoza #2094 Colonia Nueva. Mexicali, Baja California,
                Mexico.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
